<template>
  <v-container
    fluid
    class="pa-0"
  >
    <v-card-actions class="pa-0">
      <v-row class="ma-0 pa-0">
        <v-col
          cols="12"
          md="6"
          class="pb-0"
        >
          <v-btn
            color="success"
            block
            depressed
            dark
            @click="save()"
          >
            <v-icon class="mr-2">
              mdi-check-circle-outline
            </v-icon>
            CONFIRM
          </v-btn>
        </v-col>
        <v-col
          cols="12"
          md="6"
          class="pb-0"
        >
          <v-btn
            outlined
            color="gray"
            block
            @click="close()"
          >
            <v-icon class="mr-2">
              mdi-close-circle-outline
            </v-icon>
            CANCEL
          </v-btn>
        </v-col>
      </v-row>
    </v-card-actions>
  </v-container>
</template>

<script>
  export default {
    methods: {
      save () {
        this.$emit('saveFunc')
      },
      close () {
        this.$emit('closeFunc')
      },
    },
  }
</script>
